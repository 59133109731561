/**
 * Created by ApolloLogix on 2020-09-05.
 */
let wp_styling = true


const wp_styling_default = {
    "entry-title": {
        "description": "post title",
        "classes": [
            " text-center",
            " mb-3",
            " pt-5",
        ]
    }
};

// 1 layer deep
function add_mdb_to_wp_element(element_name, wp_styling) {
    var elements = document.getElementsByClassName(element_name);
    for (let i = 0; i < elements.length; i++) {
        var entry_title_styling = wp_styling[element_name]["classes"]
        for (let i2 = 0; i2 < entry_title_styling.length; i2++) {
            elements[i].className += entry_title_styling[i2];
        }
    }
}

function add_mdb_to_wp(wp_styling) {
    console.log('wp_styling:',wp_styling)
    //console.log(wp_styling["entry-title"])
    //first overwrite the default with wp_styling
    wp_styling = Object.assign(wp_styling_default, wp_styling);
    console.log('wp_styling after Object.assign:',wp_styling)
    let elements;

    // todo: this can be done by looping through the wp_styling json data
    // submit buttons
    add_mdb_to_wp_element('submit', wp_styling)

    // pagination buttons
    add_mdb_to_wp_element('pagination-single-inner', wp_styling)

    // post title
    add_mdb_to_wp_element('entry-title', wp_styling)

    // post comment
    add_mdb_to_wp_element('comment-form-comment', wp_styling)

    add_mdb_to_wp_element('textarea', wp_styling)

    // post email
    add_mdb_to_wp_element('comment-respond', wp_styling)

    add_mdb_to_wp_element('comment-form-email', wp_styling)
    add_mdb_to_wp_element('comment-form-url', wp_styling)
    add_mdb_to_wp_element('form-submit', wp_styling)

    // comment border
    add_mdb_to_wp_element('comment', wp_styling)

    // reply button
    add_mdb_to_wp_element('comment-reply-link', wp_styling)

    // text centering
    add_mdb_to_wp_element('has-text-align-center', wp_styling)

    add_mdb_to_wp_element('blocks-gallery-grid', wp_styling)

    // post meta
    elements = document.getElementsByClassName("post-meta");
    for (let i = 0; i < elements.length; i++) {
        //elements[i].className += ' btn btn-primary waves-effect';
        elements[i].className += ' list-group list-group-horizontal-md py-5';
        for (let i2 = 0; i2 < elements[i].children.length; i2++) {
            //elements[i].className += ' btn btn-primary waves-effect';
            elements[i].children[i2].className += ' list-group-item flex-fill';
        }
    }

    // post categories
    elements = document.getElementsByClassName("entry-categories-inner");
    for (let i = 0; i < elements.length; i++) {
        //elements[i].className += ' btn btn-primary waves-effect';
        elements[i].className += ' list-group list-group-horizontal-md py-1';
        for (let i2 = 0; i2 < elements[i].children.length; i2++) {
            //elements[i].className += ' btn btn-primary waves-effect';
            elements[i].children[i2].className += ' list-group-item';
        }
    }

    // post content
    elements = document.getElementsByClassName("entry-content");
    for (let i = 0; i < elements.length; i++) {
        //elements[i].className += ' <?=$colors_wp['entry-content']['static'] ?>';
    }


    // post comment meta
    elements = document.getElementsByClassName("comment-meta");
    for (let i = 0; i < elements.length; i++) {
        elements[i].className += ' list-group d-flex flex-row border border-0';
        for (let i2 = 0; i2 < elements[i].children.length; i2++) {
            elements[i].children[i2].className += ' list-group-item flex-fill list-group  border border-0';
            let el3 = elements[i].children[i2];
            for (let i3 = 0; i3 < el3.children.length; i3++) {
                console.log(el3)
                console.log(el3.children[i3])
                el3.children[i3].className += ' list-group-item list-group border border-0';

                let el4 = el3.children[i3];
                for (let i4 = 0; i4 < el4.children.length; i4++) {
                    console.log(el4)
                    console.log(el4.children[i4])
                    el4.children[i4].className += ' list-group-item flex-fill  border border-0';
                }
            }
        }
    }


    //images
    // figures
    elements = document.getElementsByTagName("figure");
    console.log(elements)
    for (let i = 0; i < elements.length; i++) {
        elements[i].className += ' figure';
        //console.log(elements[i].children)
        let images = elements[i].children[0].getElementsByTagName("img");
        for (let i2 = 0; i2 < elements[i].children.length; i2++) {
            //console.log( elements[i].children[i2].tagName)
            if (elements[i].children[i2].tagName === 'IMG') {
                elements[i].children[i2].className += ' figure-img img-fluid ';
            }
        }
    }


}


//fetch_data('wp_styling')
fetch_data1('wp_styling',add_mdb_to_wp)



