/**
 * Created by ApolloLogix on 2020-09-04.
 */

/* animated circle while site loads */

$(window).on('load', function () {
    $('#mdb-preloader').addClass('loaded');
});
$(window).ready(function () {
    //alert('ready');
    $('#mdb-preloader').remove()
})


/* wow init to get fade in of elements */
/*if () {
    new WOW().init();
}*/

/*
$( document ).ready(function() {
    new WOW().init();
});
*/

// object-fit polyfill run
objectFitImages();

/* init Jarallax */
jarallax(document.querySelectorAll('.jarallax'));

jarallax(document.querySelectorAll('.jarallax-keep-img'), {
    keepImg: true,
});



//new WOW().init();