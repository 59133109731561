/**
 * Created by ApolloLogix on 2020-09-05.
 */

/* adjust menu based on amount of menu items and rows */

// adjust menu padding bottom when menu gets a second row
function getPosition(element_id) {
    let position = window.getComputedStyle(document.getElementById(element_id), null).position;
    return position
}

function getHeight(element_id) {
    let height = document.getElementById(element_id).clientHeight;
    return height
}

function getHeightInREM(element_id) {
    //let height = document.getElementById(element_id).clientHeight;
    let height = document.getElementById(element_id).clientHeight / parseFloat($("body").css("font-size"));
    return height
}

function setPaddingBottom(element_id, size) {
    document.getElementById(element_id).style.paddingBottom = size;
}

function setPaddingBottomImportant(element_id, size) {
    document.getElementById(element_id).style.cssText = 'padding-bottom: ' + size + ' !important';
    // using style.cssText overwrites all the inline css
}

function setTop(element_id, pos) {
    document.getElementById(element_id).style.cssText = 'top: ' + pos + ' !important';
}

function adjustMenuHeightForMultiRowWithPixels() {
    let element_id = 'nav-main';
    // first check if nav-main exist
    if(document.getElementById(element_id)){
        //console.clear();

        let position = getPosition(element_id);
        //console.log('position: ' + position);

        let height = getHeight(element_id);
        //console.log('height: ' + height);

        let heightREM = getHeightInREM(element_id);
        //console.log('heightREM: ' + heightREM);

        if (position === 'fixed' && height <= 83) {
            //setPaddingBottom('header_nav-bar', '100px');
            setPaddingBottomImportant('header_nav-bar', 'inherit');
        }

        if (position === 'fixed' && height >= 142) {
            //setPaddingBottom('header_nav-bar', '100px');
            setPaddingBottomImportant('header_nav-bar', '5rem');
        }

        if (position === 'fixed' && height >= 202) {
            //setPaddingBottom('header_nav-bar', '100px');
            setPaddingBottomImportant('header_nav-bar', '7rem');
        }
        if (position === 'fixed' && height >= 261) {
            //setPaddingBottom('header_nav-bar', '100px');
            setPaddingBottomImportant('header_nav-bar', '11rem');
        }
    }


}


function adjustMenuHeightForMultiRowWithREM(element_measured_id, element_adjust_id, func, offset = 0) {
    //console.clear();

    if (document.getElementById(element_measured_id)) {
        let position = getPosition(element_measured_id);
        //console.log('position: ' + position);

        let height = getHeight(element_measured_id);
        //console.log('height: ' + height);

        let heightREM = getHeightInREM(element_measured_id) + offset;
        console.log('heightREM: ' + heightREM);

        if (position === 'fixed') {
            //setPaddingBottom('header_nav-bar', '100px');
            //setPaddingBottomImportant(element_adjust_id, heightREM + 'rem');
            func(element_adjust_id, heightREM + 'rem');
        }
    }

}


$(document).ready(function () {
    adjustMenuHeightForMultiRowWithREM('nav-main', 'header_nav-bar', setPaddingBottomImportant); // initial
    window.addEventListener('resize', adjustMenuHeightForMultiRowWithREM('nav-main', 'header_nav-bar', setPaddingBottomImportant), true); // after resize

    if (document.getElementById("toaster2")) {
        adjustMenuHeightForMultiRowWithREM('nav-main', 'toaster2', setTop, 10); // initial
        window.addEventListener('resize', adjustMenuHeightForMultiRowWithREM('nav-main', 'toaster2', setTop, 10), true); // after resize
    }

});